import axios from 'axios';
import { useState } from 'react';
import { Input, Message, Segment } from 'semantic-ui-react';

interface InviteUserProps {
  challengeName: string
  challengeId: string
}

interface Feedback {
  error: boolean
  text: string
}

function InviteUser({ challengeName, challengeId }: InviteUserProps) {
  const [username, setUsername] = useState('')
  const [message, setMessage] = useState<Feedback>({error: false, text: ''})

  function submit() {
    axios.post('/challenge/invite', {
      challengeId: challengeId,
      username: username,
    }).then(res => setMessage({error: false, text: res.data}))
    .catch(err => setMessage({error: true, text: 'Something went wrong, check the username is correct or try again later'}))
  }

  return (
    <>
      <Segment basic compact>
        Enter their username to invite them to {challengeName}
      </Segment>
      <Segment basic>
        <Input placeholder='Username'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          action={{
            color: 'green',
            labelPosition: 'left',
            icon: 'add',
            content: 'Invite',
            onClick: submit
          }} />
      </Segment>
      {message.text && 
        <Message error={message.error} style={{margin: '1rem'}}>{message.text}</Message>}
    </>
  )
}

export default InviteUser