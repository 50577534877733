import axios from "axios"
import { SyntheticEvent, useEffect, useState } from "react"
import { Button, Grid, Header, Icon, Input, Message, Modal, ModalProps, Segment } from "semantic-ui-react"
import ChallengeInvites from "../components/Challenge/ChallengeInvites"
import InviteUser from "../components/Challenge/InviteUser"
import NewChallengeModal from "../components/NewChallengeModal"
import useCopyToClipboard from "../hooks/useCopyToClipboard"
import useRequireAuth from "../hooks/useRequireAuth"
import Challenge from "../interfaces/Challenge"
import Box from "../components/Challenge/Box"
import ManyChallenges from "../interfaces/ManyChallenges"

function Challenges() {
  useRequireAuth()

  const [value, copy, clear] = useCopyToClipboard()

  const [challenges, setChallenges] = useState<Challenge[]>([])
  const [error, setError] = useState('')
  const [challengeInvite, setChallengeInvite] = useState<string | undefined>(undefined)

  const [leaveModal, setLeaveModal] = useState<Challenge | undefined>(undefined)
  const [inviteModal, setInviteModal] = useState<Challenge | undefined>(undefined)

  useEffect(() => {
    axios.get(`/challenge?waitForUpdate=false`).then((val: { data: ManyChallenges }) => {
      const { challenges, updateAvailable } = val.data
      setChallenges(challenges)
      if (updateAvailable) {
        console.log("Challenges are out of date, updating")
        axios.get(`/challenge?waitForUpdate=true`).then((val) => {
          console.log("Updated challenges")
          const { challenges } = val.data
          setChallenges(challenges)
        }).catch(e => console.log("Could not update any challenges"))
      } else {
        console.log("Already up to date, not updating")
      }
    }).catch(e => setError("Could not find any challenges"))
  }, [])

  function inviteToChallenge(challengeId: string) {
    clear()
    axios.get(`/invite/${challengeId}`).then((val) => {
      setChallengeInvite(val.data)
    })
  }

  const handleActionClick = (event: SyntheticEvent, data: ModalProps) => {
    if (data.key === 'leave') {
      // handle click event for 'done' button
      leaveChallenge(data.challengeId)
    }
  }
  function leaveChallenge(challengeId: string) {
    axios.delete(`/challenge/leave/${challengeId}`).then((val) => {
      if (val.data === true) {
        setChallenges(challenges.filter(c => c.challengeId !== challengeId));
      }
    }).catch(e => setError("Something went wrong, please try again later"))
  }

  return (
    <Grid textAlign='center' style={{ paddingTop: '3rem' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>

        {inviteModal !== undefined &&
          <Modal
            open={true}
            header={`Invite user to ${inviteModal?.name}`}
            content={<InviteUser challengeName={inviteModal.name} challengeId={inviteModal.challengeId} />}
            actions={[{ key: 'done', content: 'Close', positive: true }]}
            onClose={() => setInviteModal(undefined)}
          />}
        {leaveModal &&
          <Modal
            open={true}
            header={`Are you sure?`}
            content={`Are you sure you want to leave ${leaveModal.name}?`}
            actions={[
              "No",
              <Button negative onClick={() => leaveChallenge(leaveModal.challengeId)}>Yes</Button>,
            ]}
            onActionClick={handleActionClick}
            onClose={() => setLeaveModal(undefined)}
          />}
        {challengeInvite &&
          <Modal
            open={true}
            onClose={() => setChallengeInvite(undefined)}
          >
            <Header icon>
              <Icon name='group' />
              Copy challenge invite
            </Header>
            <Modal.Content>
              <p>
                Share this link to invite your friends!
              </p>
              <Input
                value={challengeInvite}
                action={{
                  icon: value ? 'check' : 'clipboard',
                  onClick: (() => copy(challengeInvite))
                }}
                style={{ width: 'clamp(100px, 50%, 900px)' }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color='green' inverted onClick={() => setChallengeInvite(undefined)}>
                <Icon name='checkmark' /> Done
              </Button>
            </Modal.Actions>
          </Modal>
        }
        <Header as='h1'>View Challenges</Header>
        {
          challenges ?
            challenges.sort((e1, e2) => (e1.active === e2.active) ? 0 : e1.active ? -1 : 1).map(challenge => {
              return <Box challenge={challenge} setInviteModal={setInviteModal} inviteToChallenge={inviteToChallenge} setLeaveModal={setLeaveModal} key={challenge.challengeId} />
            })
            :
            error ?
              <Message error>{error}</Message>
              :
              <Segment loading>Loading</Segment>
        }
        <ChallengeInvites addChallenge={(c) => setChallenges([...challenges, c])} />
        <br />
        <NewChallengeModal addChallenge={(c) => setChallenges([...challenges, c])} />
      </Grid.Column>
    </Grid>
  )
}

export default Challenges