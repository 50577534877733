import axios from 'axios'
import { useState } from 'react'
import { Button, Divider, Dropdown, Form, Grid, Header, Icon, Input, Menu, Modal } from 'semantic-ui-react'
import Challenge from '../interfaces/Challenge'
import DayOfWeek, { daysOfWeek } from '../interfaces/DayOfWeek'

interface NewChallengeModalProps {
  addChallenge: (c: Challenge) => void
}

function NewChallengeModal({ addChallenge }: NewChallengeModalProps) {
  const [createModal, setCreateModal] = useState(false)

  const [newChallengeName, setNewChallengeName] = useState('')
  const [newChallengeStart, setNewChallengeStart] = useState<DayOfWeek | undefined>(undefined)
  const [newChallengeEnd, setNewChallengeEnd] = useState<DayOfWeek | undefined>(undefined)

  const dropdownOptions = daysOfWeek.map((val) => ({
    key: val,
    text: val,
    value: val.toUpperCase(),
  }))

  function createChallenge() {
    axios.put(`/challenge/${newChallengeName}`, {
      start: newChallengeStart,
      end: newChallengeEnd
    }).then((val) => {
      addChallenge(val.data)
      resetCreateModal()
    })
  }

  function resetCreateModal() {
    setNewChallengeStart(undefined)
    setNewChallengeEnd(undefined)
    setNewChallengeName('')
    setCreateModal(false)
  }

  return (
    <>
      <Button content='New Challenge' primary onClick={() => setCreateModal(true)} />
      <Modal
        open={createModal}
        onClose={() => setCreateModal(false)}
      >
        <Header icon>
          <Icon name='group' />
          Create Challenge
        </Header>
        <Modal.Content>
          <p>
            Create a new challenge
          </p>
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Input placeholder='Challenge Name' value={newChallengeName} onChange={(e) => setNewChallengeName(e.target.value)} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Dropdown
                  placeholder="Start Day"
                  selection
                  options={dropdownOptions}
                  value={newChallengeStart}
                  onChange={(e, data) => setNewChallengeStart(data.value as DayOfWeek)}
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  placeholder="End Day"
                  selection
                  options={dropdownOptions}
                  value={newChallengeEnd}
                  onChange={(e, data) => setNewChallengeEnd(data.value as DayOfWeek)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={createChallenge}>
            <Icon name='checkmark' /> Submit
          </Button>
          <Button onClick={resetCreateModal}>
            <Icon name='x' /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default NewChallengeModal