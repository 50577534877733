import { Grid, Header, Icon, Segment, Step } from 'semantic-ui-react';
import { useAuth } from '../hooks/useUserAuth';
import { useNavigate } from 'react-router-dom';
import useRequireAuth from '../hooks/useRequireAuth';

function Home() {
  const navigate = useNavigate()
  useRequireAuth()

  const {loggedIn, isAuthenticated, isInChallenge} = useAuth()

  return (<>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Step challenges
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Fitbit removed the weekend warrior and workweek hustle, I made them again
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              How it works
            </Header>  
            <Step.Group widths={6}>
              <Step onClick={() => navigate('/signup')} completed={loggedIn} disabled={loggedIn}>
                <Icon name='signup' />
                <Step.Content>
                  <Step.Title>Sign up</Step.Title>
                  <Step.Description>Sign up with an account to get started</Step.Description>
                </Step.Content>
              </Step>

              <Step onClick={() => loggedIn && navigate('/fitbit')} completed={isAuthenticated} disabled={isAuthenticated}>
                <Icon name='linkify'/>
                <Step.Content>
                  <Step.Title>Link Account</Step.Title>
                  <Step.Description>Link your Fitbit account</Step.Description>
                </Step.Content>
              </Step>

              <Step onClick={() => loggedIn && navigate('/challenges')} completed={isInChallenge} disabled={isInChallenge}>
                <Icon name='group'/>
                <Step.Content>
                  <Step.Title>Join a challenge</Step.Title>
                  <Step.Description>Create a new challenge, or join your friends to see their progress throughout the week</Step.Description>
                </Step.Content>
              </Step>
            </Step.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </>
  )
}

export default Home