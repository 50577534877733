import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home';
import Fitbit from './pages/Fitbit';
import SignupForm from './pages/signup';
import LoginForm from './pages/login';
import Profile from './pages/profile';
import Challenges from './pages/Challenges';
import Navbar from './components/Navbar';
import Logout from './pages/Logout';
import ForgotPassword from './pages/ForgotPassword';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { clearAuth } from './reducers/auth';

function App() {
  const dispatch = useDispatch()

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    if (error?.response.status === 401) {
      // User is not autorised
      dispatch(clearAuth())
    }
    return Promise.reject(error);
  });

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="signup" element={<SignupForm />} />
        <Route path="login" element={<LoginForm />} />
        <Route path="forgotten" element={<ForgotPassword />} />
        <Route path="fitbit" element={<Fitbit />} />
        <Route path="profile" element={<Profile />} />
        <Route path="challenges" element={<Challenges />} />
        <Route path="logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
