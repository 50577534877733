import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react'
import useRequireAuth from '../hooks/useRequireAuth';

function Fitbit() {
  const clientId = process.env.REACT_APP_FITBIT_CLIENT_ID;

  useRequireAuth()

  const navigate = useNavigate()

  const [activitesPermission, setActivitesPermission] = useState(false)
  const [profilePermission, setProfilePermission] = useState(false)
  const [devicePermission, setDevicePermission] = useState(false)
  const [codeChallenge, setCodeChallenge] = useState('')
  const [state, setState] = useState('')

  let access = [
    { label: 'activity', value: activitesPermission, setter: setActivitesPermission },
    { label: 'profile', value: profilePermission, setter: setProfilePermission },
    { label: 'settings', value: devicePermission, setter: setDevicePermission },
  ]

  const scopes = access.filter(val => val.value).map(val => val.label)

  const scopeReq = scopes.join('+')

  useEffect(() => {
    axios.get('/fitbit/challenge')
      .then(res => {
        if (res.data.permissionGranted) navigate('/profile')
        setCodeChallenge(res.data.shortCode)
        setState(res.data.state)
      })
  }, [navigate])


  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' color='teal' textAlign='center'>
          Log-in to your Fitbit account
        </Header>
        <Form size='large'>
          <Segment>
            <Header as='h3'>
              Permissions
            </Header>
            {access.map(({ label, value, setter }) => {
              return <Form.Checkbox toggle label={label.charAt(0).toUpperCase() + label.slice(1)} checked={value} onChange={(e, data) => setter(data.checked as boolean)} />
            })}
            <Button color='teal' fluid size='large' disabled={!state} href={`https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${clientId}&scope=${scopeReq}&code_challenge=${codeChallenge}&code_challenge_method=S256&state=${state}`}>
              Login to FitBit
            </Button>
          </Segment>
        </Form>
        {/* <Message negative>
          Error messages
        </Message> */}
      </Grid.Column>
    </Grid>
  )
}

export default Fitbit