import { useAuth } from '../hooks/useUserAuth';
import { useNavigate } from 'react-router-dom';

interface useRequireAuthProps {
  redirect: string
}

const defaultProps: useRequireAuthProps = {
  redirect: '/login'
}

function useRequireAuth({ redirect } = defaultProps) {
  const navigate = useNavigate()
  const { loggedIn } = useAuth()

  if (!loggedIn) {
    navigate(redirect)
  }
}

export default useRequireAuth