import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'

export interface Auth {
    jwttoken: string | undefined
}

const initialAuth: Auth = {
    jwttoken: undefined
}

function setAxiosToken(token: string | undefined) {
    axios.interceptors.request.use((config) => {
        config.baseURL = process.env.REACT_APP_BACKEND_URL
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        } else {
            config.headers.Authorization = ''
        }
        config.headers['Access-Control-Allow-Origin'] = '*'
        return config;
    });
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuth,
    reducers: {
        updateAuth: (state: Auth, action: PayloadAction<string>) => {
            state.jwttoken = action.payload
            Cookies.set('jwttoken', action.payload)
            setAxiosToken(action.payload)
        },
        clearAuth: (state: Auth) => {
            state.jwttoken = undefined
            Cookies.remove('jwttoken')
            setAxiosToken(undefined)
        }
    },
})

// each case under reducers becomes an action
export const { updateAuth, clearAuth } = authSlice.actions

export default authSlice.reducer
