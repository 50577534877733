import axios, { AxiosResponse } from 'axios'
import { useState } from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'

const ForgotPassword = () => {
  const [username, setUsername] = useState('')
  const [targetDate, setTargetDate] = useState<Date>()
  const [stepGuess, setStepGuess] = useState<Number>(0)

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [error, setError] = useState('')
  const [message, setMessage] = useState('')

  function handleClick() {
    setError('')
    setMessage('')
    if (!username) {
      setError('No username provided')
    } else {
      // valid
      axios.post(`/user/password-target`, {
        username,
      }).then((res: AxiosResponse) => {
        setTargetDate(new Date(res.data))
      }).catch((res) => {
        setError(res.response.data.message)
      })
    }
  }

  function submitGuess() {
    setError('')
    setMessage('')
    if (!targetDate) {
      setError('No target date, please refresh the page')
    } else if (!stepGuess) {
      setError('No guess provided')
    } else if (!newPassword) {
      setError('No password provided')
    } else if (newPassword != confirmPassword) {
      setError('New password and confirm password do not match')
    } else {
      // valid
      axios.post(`/user/password-guess`, {
        username,
        targetDate,
        stepGuess,
        newPassword
      }).then((res: AxiosResponse) => {
        setMessage(res.data)
        setTargetDate(undefined)
        setUsername('')
      }).catch((res) => {
        setError(res.response.data.message)
      })
    }
  }

  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' color='teal' textAlign='center'>
          Forgotten your password?
        </Header>
        <Form size='large'>
          <Segment>
            <Form.Input fluid icon='user'
              iconPosition='left'
              placeholder='Username'
              value={username} onChange={(e) => setUsername(e.target.value)}
              disabled={!!targetDate}
            />
            {
              !targetDate ?
                <Button color='teal' fluid size='large' onClick={handleClick}>
                  Get Challenge
                </Button>
                :
                <>
                  Enter the number of steps you did on {targetDate.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}
                  <Form.Input fluid icon='numbered list'
                    iconPosition='left'
                    placeholder='Step Count'
                    value={stepGuess} onChange={(e) => e.target.value ? setStepGuess(parseInt(e.target.value)) : setStepGuess(0)}
                  />
                  <Form.Input fluid icon='lock'
                    iconPosition='left'
                    placeholder='New Password'
                    value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                    type='password'
                  />
                  <Form.Input fluid icon='lock'
                    iconPosition='left'
                    placeholder='Confirm Password'
                    value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                    type='password'
                  />
                  <Button color='teal' fluid size='large' onClick={submitGuess}>
                    Submit
                  </Button>
                </>
            }
          </Segment>
          {error &&
            <Message negative>
              {error}
            </Message>}
          {message &&
            <Message positive>
              {message}
            </Message>}
        </Form>
      </Grid.Column>
    </Grid>
  )
}

export default ForgotPassword