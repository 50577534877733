import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react';
import { updateAuth } from '../reducers/auth';

const SignupForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const [error, setError] = useState('')

  function handleSignup() {
    if (!username) {
      setError('No username provided')
    } else if (!password) {
      setError('No password provided')
    } else if (password !== passwordConfirm) {
      setError('Passwords do not match')
    } else {
      // valid
      setError('')
      axios.post(`/user/signup`, {
        username,
        password,
        inviteId: searchParams.get('invite')
      }).then((res: AxiosResponse) => {
        dispatch(updateAuth(res.data))
        navigate('/fitbit')
      }).catch((res) => {
        setError(res.response.data.message)
      })
    }
  }

  return (
  <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 450 }}>
      <Header as='h2' color='teal' textAlign='center'>
        Register for an account
      </Header>
      <Form size='large'>
          <Segment>
            <Form.Input fluid icon='user'
              iconPosition='left'
              placeholder='Username'
              value={username} onChange={(e) => setUsername(e.target.value)}
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Confirm Password'
              type='password'
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />

            <Button color='teal' fluid size='large' onClick={handleSignup}>
              Login
            </Button>
          </Segment>
          {error &&
            <Message negative>
              {error}
            </Message>}
        </Form>
      <Message>
        Already registered? <Link to='/login'>Sign In</Link>
      </Message>
    </Grid.Column>
  </Grid>
)};

export default SignupForm