import axios, { AxiosResponse } from 'axios'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'
import { updateAuth } from '../reducers/auth'

const LoginForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [error, setError] = useState('')

  function handleLogin() {
    if (!username) {
      setError('No username provided')
    } else if (!password) {
      setError('No password provided')
    } else {
      // valid
      setError('')
      axios.post(`/user/login`, {
        username,
        password
      }).then((res: AxiosResponse) => {
        dispatch(updateAuth(res.data.token))
        if (res.data.permissionGranted) {
          navigate('/challenges')
        } else {
          navigate('/fitbit')
        }
      }).catch((res) => {
        setError(res.response.data.message)
      })
    }
  }

  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' color='teal' textAlign='center'>
          Log-in to your account
        </Header>
        <Form size='large'>
          <Segment>
            <Form.Input fluid icon='user'
              iconPosition='left'
              placeholder='Username'
              value={username} onChange={(e) => setUsername(e.target.value)}
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}

            />

            <Button color='teal' fluid size='large' onClick={handleLogin}>
              Login
            </Button>
          </Segment>
          {error &&
            <Message negative>
              {error}
            </Message>}
        </Form>
        <Message>
          New to us? <Link to='/signup'>Sign Up</Link>
        </Message>
        <Message>
          Forgotten your password? <Link to='/forgotten'>Click Here</Link>
        </Message>
      </Grid.Column>
    </Grid>
  )
}

export default LoginForm