import { useState } from 'react'

type CopiedValue = string | null
type CopyFn = (text: string) => Promise<boolean> // Return success
type ClearFn = () => Promise<void> // Clear coppied value

function useCopyToClipboard(): [CopiedValue, CopyFn, ClearFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null)

 async function copy(text: string) {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      return false
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)
      return true
    } catch (error) {
      console.warn('Copy failed', error)
      setCopiedText(null)
      return false
    }
  }

  async function clear() {
    setCopiedText(null)
  }

  return [copiedText, copy, clear]
}

export default useCopyToClipboard