import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../store";


const useAuthImpl = () => {
  const jwttoken = useSelector((state: IRootState) => state.auth.jwttoken)

  const loggedIn = !!jwttoken
  const [isAuthenticated, setIsAuthenticated] = useState(loggedIn)
  const [isInChallenge, setIsInChallenge] = useState(false)

  useEffect(() => {
    if (jwttoken) {
      axios.get('/user/authenticated')
        .then(res => {
          setIsAuthenticated(res.data.isAuthenticated)
          setIsInChallenge(res.data.isInChallenge)
        })
    } else {
      setIsAuthenticated(false)
      setIsInChallenge(false)
    }
  }, [loggedIn])

  return { loggedIn, isAuthenticated, isInChallenge }
}

export const useAuth = useAuthImpl
