import axios from 'axios'
import { useEffect, useState } from 'react'
import { Button, Header, Message, Segment } from 'semantic-ui-react'
import Challenge from '../../interfaces/Challenge'
import SelfUser from '../../interfaces/SelfUser'

interface ChallengeInvitesProps {
  addChallenge: (c: Challenge) => void
}

function ChallengeInvites({ addChallenge }: ChallengeInvitesProps) {
  const [user, setUser] = useState<SelfUser>()
  const [error, setError] = useState('')

  useEffect(() => {
    axios.get(`/user/profile`).then((val) => {
      setUser(val.data)
    }).catch(e => setError("Could not find user"))
  }, [])

  function acceptInvite(inviteId: string) {
    axios.get(`/challenge/accept/${inviteId}`).then((val) => {
      addChallenge(val.data)

      if (user?.challengeInvites) {
        let temp = { ...user }
        temp.challengeInvites = temp.challengeInvites.filter(val => val.challengeId !== inviteId)
        setUser(temp)
      }
    }).catch(e => setError("Could not accept challenge"))
  }

  function rejectInvite(inviteId: string) {
    axios.get(`/challenge/reject/${inviteId}`).then(() => {
      if (user?.challengeInvites) {
        let temp = { ...user }
        temp.challengeInvites = temp.challengeInvites.filter(val => val.challengeId !== inviteId)
        setUser(temp)
      }
    }).catch(e => setError("Could not reject challenge"))
  }

  return <>
    {user?.challengeInvites && user.challengeInvites.length > 0 &&
      <>
        <Header as='h3'>Challenge Invites</Header>
        <Segment.Group>
          {user.challengeInvites.map(({ challengeId, challengeName, inviterName }) => {
            return <Segment key={challengeId}>
              <span>{inviterName} has invited you to {challengeName}</span>
              <Button title='Accept' color='green' onClick={() => acceptInvite(challengeId)} icon='checkmark' />
              <Button title='Decline' color='red' onClick={() => rejectInvite(challengeId)} icon='trash' />
            </Segment>
          })}
        </Segment.Group>
      </>
    }

    {error && <Message error>{error}</Message>}
  </>
}

export default ChallengeInvites