import Challenge from '../../interfaces/Challenge'
import { Button, Container, Header, Icon, Image, Popup, Segment } from 'semantic-ui-react'
import { timeSince } from '../../utils/time'

interface ChallengeBoxProps {
  challenge: Challenge
  setInviteModal: (challenge: Challenge) => void
  inviteToChallenge: (challengeId: string) => void
  setLeaveModal: (challenge: Challenge) => void
}

function Box({ challenge, setInviteModal, inviteToChallenge, setLeaveModal }: ChallengeBoxProps) {
  return (
    <Container style={{ marginTop: '2rem' }}>
      <Segment.Group >
        <Segment color={challenge.active ? 'green' : 'orange'} inverted>
          <Header as='h3' style={{ display: 'inline' }}>{challenge.name}</Header>
          <Popup
            content={
              <Button.Group vertical>
                <Button onClick={() => setInviteModal(challenge)} positive>Invite user</Button>
                <Button onClick={() => inviteToChallenge(challenge.challengeId)}>Create Link</Button>
                <Button onClick={() => setLeaveModal(challenge)} negative>Leave</Button>
              </Button.Group>
            }
            on='click'
            trigger={<Icon name="dropdown" link />}
            style={{ padding: 0, zIndex: 0 }}
            position='bottom center'
            basic
          />
        </Segment>

        {challenge.data.map(({ name, latestStepCount, latestUpdate, profilePic }) => {
          var max_steps = challenge.data.map(c => c.latestStepCount).sort((e1, e2) => e2 - e1)[0]
          var full_bar = max_steps + (5_000 - max_steps % 5_000)
          return <Segment key={name} className='transparent py-half'>
            <Image src={profilePic} avatar />
            {name} has done {latestStepCount.toLocaleString()}&nbsp;steps ({timeSince(new Date(latestUpdate))})
            <div className="user-row-after" style={{ width: `${100 * latestStepCount / full_bar}%`, backgroundColor: challenge.active ? '#8cf1a4' : '#ffdd9e' }}></div>
          </Segment>
        })}
      </Segment.Group>
    </Container>
  )
}

export default Box