import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import { Auth } from './reducers/auth'
import authReducer from './reducers/auth'

const persistedAuthReducer = persistReducer({ key: 'auth', storage: storage }, authReducer)

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
  },
})

export const persistor = persistStore(store)

export default store

// Some typescript manipulation to fix the persisted store return type
interface storeType extends Omit<ReturnType<typeof store.getState>, 'auth'> {
    auth: Auth
}

export type IRootState = storeType
