import axios from 'axios';
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import Cookies from 'js-cookie'

axios.interceptors.request.use((config) => {
  config.baseURL = process.env.REACT_APP_BACKEND_URL
  const jwttoken = Cookies.get('jwttoken')
  if (jwttoken) {
    config.headers.Authorization = `Bearer ${jwttoken}`
  } else {
    config.headers.Authorization = ''
  }
  config.headers['Access-Control-Allow-Origin'] = '*'
  return config;
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // TODO: Get react strict mode working again
  // <React.StrictMode>
  <CookiesProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </CookiesProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
