import { Container, Header, Button, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearAuth } from '../reducers/auth';
import useRequireAuth from '../hooks/useRequireAuth';

function Logout() {
  useRequireAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  const handleLogout = () => {
    dispatch(clearAuth())
    
    navigate('/');
  };

  return (
    <Container text textAlign="center">
      <Header as="h1">Logout</Header>
      <p>Are you sure you want to log out?</p>
      <Button color="red" onClick={handleLogout}>
        <Icon name="sign-out" />
        Logout
      </Button>
    </Container>
  );
}

export default Logout