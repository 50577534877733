function timeSince(date: Date): JSX.Element {
    if (Number.isNaN(date.getTime()) || date.getTime() === 0) {
      return <>never</>
    }

    const seconds = Math.floor((Date.now() - date.getTime()) / 1000) - date.getTimezoneOffset() * 60;
    const intervals = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
      { label: "minute", seconds: 60 },
      { label: "second", seconds: 1 },
    ];

    for (const interval of intervals) {
      if (seconds >= interval.seconds) {
        const count = Math.floor(seconds / interval.seconds);
        return <>{count}&nbsp;{interval.label}{count > 1 ? "s" : ""}&nbsp;ago</>;
      }
    }

    return <>just&nbsp;now</>;
  }

export { timeSince }