import { Menu, MenuItemProps } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useUserAuth';

const Navbar = () => {
  const navigate = useNavigate();

  const { loggedIn, isAuthenticated } = useAuth();

  const activeItem = window.location.pathname.slice(1)

  const handleItemClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: MenuItemProps,
  ) => {
    navigate(`/${data.name}`);
  };

  const leftItems = [
    { name: '', label: 'Home' },
  ];

  if (loggedIn) {
    leftItems.push({ name: 'profile', label: 'Profile' })
    leftItems.push({ name: 'challenges', label: 'Challenges' })
  }
  if (loggedIn && !isAuthenticated) leftItems.push({ name: 'fitbit', label: 'Fitbit' })

  let rightItems: { name: string, label: string }[]

  if (!loggedIn) {
    rightItems = [
      { name: 'login', label: 'Login' },
      { name: 'signup', label: 'Sign up' },
    ];
  } else {
    rightItems = [
      { name: 'logout', label: 'Logout' },
    ];
  }

  const renderLeftItems = () => {
    return leftItems.map((item) => (
      <Menu.Item
        key={item.name}
        name={item.name}
        active={activeItem === item.name}
        onClick={handleItemClick}
      >
        {item.label}
      </Menu.Item>
    ));
  };

  const renderRightItems = () => {
    return rightItems.map((item) => (
      <Menu.Item
        key={item.name}
        name={item.name}
        active={activeItem === item.name}
        onClick={handleItemClick}
      >
        {item.label}
      </Menu.Item>
    ));
  };

  return (
    <Menu pointing secondary>
      <Menu.Menu position="left">{renderLeftItems()}</Menu.Menu>
      <Menu.Menu position="right">{renderRightItems()}</Menu.Menu>
    </Menu>
  );
};

export default Navbar;
