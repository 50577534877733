import { useEffect, useState } from "react"
import { Grid, Header, Segment } from "semantic-ui-react"
import axios from "axios"
import useRequireAuth from "../hooks/useRequireAuth"
import SelfUser from "../interfaces/SelfUser"
import { timeSince } from "../utils/time"

function Profile() {
  useRequireAuth()

  const [user, setUser] = useState<SelfUser>()

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/profile`).then((val) => {
      setUser(val.data)
    })
  }, [])

  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      {user &&
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h1'>Welcome {user.name}</Header>
          <Segment.Group>
            <Segment>
              {user.weeklyStepCount.toLocaleString()} steps ({timeSince(new Date(user.latestUpdate))}) in the last 7 days
            </Segment>
          </Segment.Group>
        </Grid.Column>
      }
    </Grid>
  )
}

export default Profile